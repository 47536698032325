<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";


/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "liste des utilisateurs à supprimer",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Utilisateurs",
          active: true,
        },
      ],
      userType: ["CONDUCTEUR", "PARTICULIER", "TAXIMAN", "SOCIETE"],
      url_link: "",
      userTypeSelect: "",
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "action", label: "Cocher" },
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "email", sortable: true },
        { key: "tel", sortable: true, label: "Tel" },
        { key: "pays", sortable: true, label: "Pays" },
        { key: "type", sortable: true, label: "Type" },
        { key: "trajet", sortable: true, label: "Trajets Publiés" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "manuel", sortable: true, label: "Manuel" },
      
      ],
      showDisable: false,
      showEchec: false,
      showSucess: false,
      userToUse: 0,
      selected: [],
      selectAll: false,
      filteredData: [],
      startValue: 1,
      endValue: 500,

      userChargeLoader: false,
      newDataUser: [],
      newStart: false,
      totalValue:0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();

    this.userTotal();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
     
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
      /*     if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          } */

          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            email: user.email,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.dataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

      }
    },

    async actifpage(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/users?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }

          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            email: user.email,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.newDataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.userChargeLoader = false;
        this.totalRows = formattedTable.length;

      }
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        this.paginatedDate = [];
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }

          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            email: user.email,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.newDataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;

        this.userChargeLoader = false;
        this.totalRows = formattedTable.length;
      }
    },


    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },



    //Suppression en lot
    select() {
      if (this.filteredData.length == 0) this.filteredData = this.newDataUser.length != 0 ? this.newDataUser : this.dataUser;
      this.selected = [];
      if (!this.selectAll) {
        for (let index = 0; index < this.perPage; index++) {

          if(this.filteredData[index])
          this.selected.push(this.filteredData[index].id);
        }
       
      }

    },
    async disabledUser() {

      this.showDisable = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-users",
        {
          users: this.selected,
        },
        false
      );

      if (data && data.data) {
        this.selected = [];
        this.selectAll = false;
        this.$toast.success("Opération effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      } else {


   this.$toast.error("Echec de la suppression", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    },

 
    onFiltered(filteredItems) {

      this.filteredData = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 100;
     
      this.actifpage(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
    
      this.actifpage(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
     
      this.actifpage(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
     
      this.actifpage(this.startValue, this.endValue);
    },
   


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="dataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
           
            <div>
              <h4 class="card-title mt-4">
                 Total d'utilisateurs : {{ totalRows }}
              </h4>
            </div>
            <transition mode="out-in">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <li>
                    <span class="interval"> {{ startValue }}</span> -
                    <span class="interval">{{ endValue }}</span> sur
                    {{ totalValue }}
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <button
                      class="myBouton"
                      @click="firstVal()"
                      :disabled="startValue == 1"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Prem"
                    >
                      <ion-icon name="play-skip-back-outline"></ion-icon>
                    </button>
                    <button
                      type="button"
                      class="myBouton"
                      @click="decrement()"
                      :disabled="endValue<= 500"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Préc"
                    >
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="increment()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Suiv"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="incrementToLast()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Dern"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="play-skip-forward-outline"></ion-icon>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
      
                <div class="row  d-flex align-items-center justify-content-between mt-4">
                  <div class="p-3">
                 
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                <div class="input ">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="recherche"
                          @keyup.enter="userSearch"
                    />
                  </div>
      
              </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <div class="mt-3 mb-2">
                    <label class="form-checkbox"
                      >Tout cocher
                      <input
                        type="checkbox"
                        v-model="selectAll"
                        @click="select"
                      />
                      <i class="form-icon"></i>
                    </label>
                    <b-button
                      class="ml-5"
                      variant="danger"
                      v-if="selected.length != 0"
                      @click="showDisable = true"
                      >Supprimer</b-button
                    >
                  </div>
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataUser.length != 0 ? newDataUser : dataUser"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <input
                        type="checkbox"
                        :value="row.item.id"
                        v-model="selected"
                      />
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                    
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          :aria-controls="trajet"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <li>
                    <span class="interval"> {{ startValue }}</span> -
                    <span class="interval">{{ endValue }}</span> sur
                    {{ totalValue }}
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <button
                      class="myBouton"
                      @click="firstVal()"
                      :disabled="startValue == 1"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Prem"
                    >
                      <ion-icon name="play-skip-back-outline"></ion-icon>
                    </button>
                    <button
                      type="button"
                      class="myBouton"
                      @click="decrement()"
                      :disabled="endValue<= 500"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Préc"
                    >
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="increment()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Suiv"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="incrementToLast()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Dern"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="play-skip-forward-outline"></ion-icon>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
                  </div>
                </div>
             
          </div>
        </div>
      </div>
    </div>

    <b-
      id="-backdrop"
      centered
      v-model="showDisable"
      title="Voulez-vous vraiment suspendre ces utilisateurs ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="disabledUser"
          >Oui</b-button
        >
      </div>
    </b->
  </Layout>
</template>
<style>
   thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}


.input {
  
  right: 13px;
position: absolute;
display: flex;
justify-content: center;
width: 200px;
height: 35px;
top: 0;
margin-top: 10px;

flex: 60%;


box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
background: #ffffff;
}

.input input {
padding: 3px 3px 3px 8px;
position: absolute;
top: 0;
width: 100%;
height: 100%;
border: none;
outline: none;
font-size: 18px;
color: #495057;
font-size: 1rem;
line-height: 1.5;
font-weight: 500;
}
.input input:focus {
border: 3px solid #d6d2f8;
}
.inputCol{
position: relative;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

.table-responsive input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
background-color: #d5d5d5; 

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}
.checker {
  margin-top: 0.444rem;
  display: inline-flex;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.656rem;
  margin-bottom: 0;

}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}
input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}

input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}
input[type="checkbox"]:checked:after {
  display: block;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -14%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

</style>
